/**
 * @created by Plamena Gancheva on 08/11/2021
 * @last-updated by Zheko Hristov on 25/12/2022
 *
 * Cookies scripts
 **/

var cookies = (function ($) {
    function init() {
        askCookiesPermission();
    }

    function askCookiesPermission() {
        window.cookieconsent.initialise({
            palette: {
                popup: {
                    background: '#89c33c',
                    text: '#fefefe',
                },
                button: {
                    background: '#333333',
                    text: '#fefefe',
                },
            },
            theme: 'classic',
            content: {
                message:
                    'Продължавайки да използвате този сайт, вие се съгласявате с използването на бисквитки',
                link: 'Още...',
                dismiss: 'Разбрах!',
                href: 'https://plodove-varna.com/bg/privacy-policy',
            },
        });
    }

    return {
        init: init,
    };
})(jQuery);

export default cookies;
