var sliderScripts = (function ($) {
    'use strict';
    function init() {
        bindHomeSlider();
    }

    function destroy() {
        $('.home-slider__container--inner').owlCarousel('destroy');
    }

    function bindHomeSlider() {
        initializeHomePageTopSlider();

        // initialize home page top container slaider
        function initializeHomePageTopSlider() {
            $('.home-slider__container--inner').owlCarousel({
                loop: true,
                margin: 30,
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                nav: true,
                dots: false,
                center: true,
                stagePadding: 0,
                responsive: {
                    0: {
                        items: 1,
                        margin: 15,
                        stagePadding: 30,
                    },
                    991: {
                        items: 2,
                    },
                },
            });
        }

        // reset owl carousel autoplayTimeout after user action
        $('.home-slider__container--inner').on('changed.owl.carousel', function () {
            $('.home-slider__container--inner').trigger('stop.owl.autoplay');
            $('.home-slider__container--inner').trigger('play.owl.autoplay');
        });
    }

    return {
        init: init,
        destroy: destroy,
    };
})(jQuery);

export default sliderScripts;
